import { render, staticRenderFns } from "./Userslist.vue?vue&type=template&id=7b66f2e7&scoped=true"
import script from "./Userslist.vue?vue&type=script&lang=js"
export * from "./Userslist.vue?vue&type=script&lang=js"
import style0 from "../css/pages/userslist.scss?vue&type=style&index=0&id=7b66f2e7&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b66f2e7",
  null
  
)

export default component.exports