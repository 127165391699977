<template>
	<div class="userslist">
		<div class="container">
			<div class="header d-flex">
				<h1 class="title-search">{{$t("titles.customers")}}</h1>
			</div>
			<div class="fond-white row">
				<div class="col-md-6">
					<div class="d-flex">
						<div v-if="$store.state.domain == 'esthetics'" ref="createBtn" id="create-btn" class="btn btn-checkable btn-secondary" v-on:click="createClient = !createClient; $event.target.setAttribute( 'checked', createClient )">{{ $t('common.createclient') }}</div>
					</div>
					<div v-if="!createClient" class="icon-search">
						<i class="fas fa-search"></i>
					</div>
					<form class="d-block form" @submit = "update" onsubmit = "event.preventDefault()">
						<div :class="createClient ? 'expandable visible' : 'expandable'">
						<i class="icon-search fas fa-user-plus icon-user"></i>
							<div :class="'form-group' + ( createClient && error.gender ? ' error' : '' )" data-target-input="nearest">
								<div class="radio radio-primary">
									<input value="female" id="create-gender-f" type="radio" v-model="createGender"/>
									<label for="create-gender-f">{{ $t('common.woman') }}</label>
								</div>
								<div class="radio radio-primary">
									<input value="male" id="create-gender-m" type="radio" v-model="createGender"/>
									<label for="create-gender-m">{{ $t('common.man') }}</label>
								</div>
								<!--div class="radio radio-primary">
									<input value="child" id="create-gender-c" type="radio" v-model="createGender"/>
									<label for="create-gender-c">{{ $t('common.young') }}</label>
								</div-->
							</div>
						</div>
						<div class="input-group">
							<input :class="'form-control' + ( createClient && error.fname ? ' error' : '' )" v-model="queries.fname" @input="update()" :placeholder="$t('common.fname')" type="text">
						</div>
						<div class="input-group">
							<input :class="'form-control' + ( createClient && error.lname ? ' error' : '' )" v-model="queries.lname" @input="update()" :placeholder="$t('common.lname')" type="text">
						</div>
						<div class="input-group">
							<input :class="'form-control' + ( createClient && error.phone ? ' error' : '' )" v-model="queries.phone" @input="update()" :placeholder="$t('common.phone')" type="text">
						</div>
						<div class="input-group">
							<input :class="'form-control' + ( createClient && error.email ? ' error' : '' )" v-model="queries.email" @input="update()" :placeholder="$t('common.email')" type="text">
						</div>
						<div :class="createClient ? 'expandable visible' : 'expandable'">
							<div id="create-birthdate" :class="'input-group date' + ( error.birthdate ? ' error' : '' )" data-target-input="nearest">
								<input class="form-control datetimepicker-input form-control" data-target="#create-birthdate" :placeholder="$t('common.datebirth')" onkeydown="return false;" type="text">
								<span class="input-group-append" data-target="#create-birthdate" data-toggle="datetimepicker">
									<div class="input-group-text"><i class="fa fa-calendar"></i></div>
								</span>
							</div>
							<div class="d-flex">
								<StatusButton id="create-user-btn" type="primary" v-on:click="createUser">{{ $t('common.save') }}</StatusButton>
							</div>
						</div>
						<!--div :class="'form-group' + ( createClient ? ' d-none' : '' )">
							<input id="search-btn" class="btn btn-primary" type="submit" :value="$t('common.search')" >
						</div-->
					</form>
				</div>
				<div class="col-md-6" :class="{ 'd-none': createClient }">
					<h2 class="title-search2 title-log">{{ $t("common.users") }}</h2>
					<div class="list-client">
						<div id="users-list">
							<router-link v-for="user in users" :key = "user.id" v-if="!user.unregistration || !$dayjs(user.unregistration).isValid()" :to="'/user?id=' + user.id" class="user-id">
								{{ user.fname + " " + user.lname }}
								<br>
								<div class="block-contact">
									<div class="phone">{{ user.phone }}</div>
									<div class="mail">{{ user.email }}</div>
								</div>
							</router-link>
							<div v-if="!users.length" class="no-user">
								{{ $t("common.nouser") }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import StatusButton from "../components/StatusButton.vue"
	import dayjs from 'dayjs'

	export default {
		components: {
			StatusButton
		},
		data() {
			return {
				createClient: false,
				createGender: "",
				createBirthdate: "",
				error: {},
				users: {
				},
				queries : {
					fname: "",
					lname: "",
					phone: "",
					email: "",
				}
			}
		},
		watch: {
			createClient( value ) {
				if ( !value ) {
					this.queries.fname = ""
					this.queries.lname = ""
					this.queries.phone = ""
					this.queries.email = ""
					this.createGender = ""
					this.createBirthdate = ""
					this.$refs.createBtn.setAttribute( "checked", false )
					this.update()
				}
			}
		},
		methods: {
			update() {
				if ( this.createClient ) {
					return
				}

				if ( this.queries.fname === "" && this.queries.lname === "" && this.queries.phone === "" && this.queries.email === "" ) {
				//	this.$api.salonsusers.get( { "{join:left}": { users: { "{users.id}": "salonsusers.userid" } }, "{coalesce}": ["fname", "lname", "phone", "email"], "{fields}": ["salonsusers.id","salonsusers.unregistration","loyalty"], "{sort}": "desc:loyalty", "{max}": 10, "salonsusers.unregistration": "" } ).then( response => {
					this.$api.salonsusers.get( { "{sort}": "desc:loyalty", "{max}": 10, [this.$store.state.domain + "users.unregistration"]: "" } ).then( response => {
						this.users = response.data
					}).catch(error => console.log(error))
					return
				}
				var query = {
					"fname[~*]": this.queries.fname || undefined,
					"lname[~*]": this.queries.lname || undefined,
					"phone[~*]": this.queries.phone || undefined,
					"email[~*]": this.queries.email || undefined,
				};
				this.$api.get( "/users/search", query ).then( response => {
					this.users = response.data;
				}).catch(error => console.log(error))
			},
			createUser( callback ) {
				if ( this.$store.state.domain == 'coachs' ) {
					callback( -1 )
					return
				}

				let msg
				this.error = {}

				if ( this.createGender === "" ) {
					this.error.gender = true
					msg = msg || this.$t( "errors.missing" )
				}
				if ( this.createBirthdate === "" ) {
					this.error.birthdate = true
					msg = msg || this.$t( "errors.missing" )
				}
				[ "fname", "lname", "phone"/*, "email"*/ ].forEach( elem => {
					if ( this.queries[elem] == "" ) {
						this.error[elem] = true
						msg = msg || this.$t( "errors.missing" )
					}
				})
				// if ( !this.queries.phone.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/) ) {
				// 	this.error.phone = true
				// 	msg = msg || this.$t( "errors.invalidphone" )
				// }
				// if ( this.queries.email.length > 0 && !this.queries.email.match(/^[a-z0-9_\-.]+@[a-z0-9-.]+$/) ) {
				// 	this.error.email = true
				// 	msg = msg || this.$t( "errors.invalidemail" )
				// }

				if ( Object.keys( this.error ).length > 0 ) {
					callback( false, msg )
				} else {
					const data = {
						fname: this.queries.fname,
						lname: this.queries.lname,
						phone: this.queries.phone,
						email: this.queries.email,
						gender: this.createGender,
						birthdate: this.createBirthdate,
					}
					this.$api.salonsusers.post( data ).then( response => {
						callback( true )
						setTimeout( () => {
							this.createClient = false
							this.$router.push( "/user?id=" + response.data[0].id )
						}, 600 )
					}).catch( error => {
						callback( false, this.$t( "errors." + error.response.data.error ) )
					})
				}
			}
		},
		mounted() {
			this.update();

			const dt = $(this.$el).find('#create-birthdate')
			dt.datetimepicker({
				"format": "DD MMM YYYY",
				"widgetPositioning": { "vertical": "top", "horizontal": "right" },
				"debug": ( process.env.VUE_APP_MODE === "development" ),
				"icons": { "time": "fa fa-clock" },
				"locale": this.$i18n.locale,
				"maxDate": dayjs()
			})
			dt.on( "change.datetimepicker", ( e ) => {
				this.createBirthdate = e.date.format( "YYYY-MM-DD" )
			})

			this.$api.salonsusers.watch( event => {
				if ( event.method === "POST" ) {
					let queries = this.queries;
					let user = event.data[0];
					if ( ( user.fname != "" && user.fname.toLowerCase().indexOf( queries.fname.toLowerCase() ) >= 0 ) ||
						( user.lname != "" && user.lname.toLowerCase().indexOf( queries.lname.toLowerCase() ) >= 0 ) ||
						( user.phone != "" && user.phone.toLowerCase().indexOf( queries.phone.toLowerCase() ) >= 0 ) ||
						( user.email != "" && user.email.toLowerCase().indexOf( queries.email.toLowerCase() ) >= 0 ) ) {
						this.users.push( user )
					}
				} else if ( event.method === "PATCH" ) {
					for ( var i = 0; i < this.users.length; i++ ) {
						if ( this.users[i].id === event.data[0].id ) {
							if ( event.data[0].userid ) {
								this.users[i] = this.$store.getters.getUser( event.data[0].userid )
							} else {
								Object.assign( this.users[i], event.data[0] )
							}
							break;
						}
					}
				} else if ( event.method === "DELETE" ) {
					for ( var i = 0; i < this.users.length; i++ ) {
						if ( this.users[i].id === event.data[0].id ) {
							this.users.splice( i, 1 );
							break;
						}
					}
				}
			});
		}
	}
</script>


<style scoped lang="scss" src="../css/pages/userslist.scss"/>
